import React, { Fragment } from "react";
 import Layout from "../components/layout";
import SEO from "../components/seo";
import { BtnContainer, DownloadBtn, DownloadTitle, SectionContainer } from "../styles/prez";
import { Banner } from "../styles/common";
import downloadFileVersionEng from "../pdf/XHUB-ENGLISH.pdf";
import downloadFileVersionFR from "../pdf/XHUB-FRANCAISE.pdf";

const data = [
    {
    title: "Download in English",
    fileName: downloadFileVersionEng,
    reverse: true,
},
{
    title: "Download in French",
    fileName: downloadFileVersionFR,
    reverse: false,
}
]
const DownloadPresentationPage = () => (
 <Layout>
    <Fragment>
        <SEO
            title="Download-Prez | x-hub.io"
            keywords={[
            "xhub",
            "devoxx",
            "devoxxmorocco",
            "devoxx4kids",
            "morocco",
            "casablanca",
            "x-hub.io",
            "devc",
            "Application & Technologies",
            ]}
        />
        <Banner download overlay>
            <SectionContainer>
                <DownloadTitle>. Our Presentation</DownloadTitle>
                <BtnContainer>
                    {data.map((elem)=>
                    <DownloadBtn href={elem.fileName} download reverse={elem.reverse} >
                    <span />
                    <span />
                    <span />
                    <span />
                    {elem.title}
                    </DownloadBtn>
                    )}
                </BtnContainer>
            </SectionContainer>
        </Banner>
    </Fragment>
   </Layout>
)

export default DownloadPresentationPage