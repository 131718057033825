import styled from "styled-components";
import { InTouch1, InTouch2, InTouch3, InTouch4 } from "./keyframes";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-cotent: center;
  align-items: center;
  with: 100%;
  height: 100%;
`
export const BtnContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  ${({ theme }) => theme.md`
    flex-direction: column;
    `}
`
export const DownloadBtn = styled.a`
  width: 250px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 16px;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 900;
  text-decoration: none;
  position: relative;
  color: ${props => (props.reverse ? "white" : "#551a8b")};
  overflow: hidden;
  margin-right: 30px;
  box-shadow: 0 0 120px
    ${props =>
      props.reverse ? "white" : "rgba(43, 29, 72, 0.3)"};
  background: ${props =>
    props.reverse ? props.theme.primaryColor : "white"};
    ${({ theme }) => theme.md`
    margin: 0px 0px 20px 0px;
    `}
  span {
    position: absolute;
  }
  span:nth-child(1) {
    top: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: linear-gradient(
      to right,
      ${props => (props.reverse ? props.theme.primaryColor : "white")},
      ${props => props.theme.primaryLightColor}
    );
    animation: ${InTouch1} linear 2s infinite;
  }

  span:nth-child(2) {
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(
      to bottom,
      ${props => (props.reverse ? props.theme.primaryColor : "white")},
      ${props => props.theme.primaryLightColor}
    );
    animation: ${InTouch2} linear 2s infinite;
    animation-delay: 1s;
  }

  span:nth-child(3) {
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: linear-gradient(
      to left,
      ${props => (props.reverse ? props.theme.primaryColor : "white")},
      ${props => props.theme.primaryLightColor}
    );
    animation: ${InTouch3} linear 2s infinite;
  }

  span:nth-child(4) {
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(
      to top,
      ${props => (props.reverse ? props.theme.primaryColor : "white")},
      ${props => props.theme.primaryLightColor}
    );
    animation: ${InTouch4} linear 2s infinite;
    animation-delay: 1s;
  }
`

export const DownloadTitle = styled.h2`
text-align: center;
  font-family: Lato;
  text-align:  center;
  font-weight: 800;
  color: white;
  font-size: 36px;
  width: 100%;
  display: flex;
  justify-content: center;
`